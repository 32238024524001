import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "../firebase";
import split_query from "../utils/helper/split_query";
import { useTranslation } from "react-i18next";
import LoadingCircular from "./LoadingCircular";
import InterStyled from "../styles/InterStyled";
import { MenuItem, Select } from "@mui/material";
import KeepoalaThemeGetter from "../styles/KeepoalaThemeGetter";
import { Container, ShopLogo } from "./ReturnFeedback";
import Button from "../styles/Button";
import { FormControl } from "@material-ui/core";

export default function ReturnFeedbackIntro() {
  const { search } = useLocation();
  const query_params: any = split_query(search);
  const db = firebase.firestore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const NO_PLATFORMS_FOUND = `${t("No platforms found for")} "${query_params.shop}"`;

  const [platforms, setPlatforms] = useState<string[]>([]);

  const [selected_platform, setSelectedPlatform] = useState<string | undefined>(undefined);

  const formatLabel = useCallback((value: string) => {
    return value.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1));
  }, []);

  const isDomain = useCallback((value: string) => {
    const domain_regex = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[a-zA-Z]{2,3})?$/i;
    return domain_regex.test(value);
  }, []);

  const fetchPlatforms = useCallback(() => {
    return db
      .collection("Shops")
      .where("name", "==", query_params.shop)
      .get()
      .then((shop_query_snap) => {
        if (shop_query_snap.size > 0) {
          const shop_doc = shop_query_snap.docs[0].data();
          if (shop_doc.platforms !== undefined && Array.isArray(shop_doc.platforms) && shop_doc.platforms.length > 0) {
            setPlatforms(shop_doc.platforms);
            setSelectedPlatform(shop_doc.platforms[0]);
          }
        } else {
          setPlatforms(["EMPTY"]);
        }
      });
  }, [query_params.shop, db]);

  const platformConfirmedHandler = () => {
    if (selected_platform !== undefined && isDomain(selected_platform)) {
      navigate(
        `/returnfeedback?shop=${query_params.shop}&use_firstname=true&platform=${selected_platform
          .split(".")[0]
          .toLowerCase()
          .replace(" ", "_")}`
      );
    } else {
      navigate(
        `/returnfeedbackmanual?shop=${query_params.shop}&location=${(selected_platform ?? "")
          .toLowerCase()
          .replace(" ", "_")}`
      );
    }
  };

  useEffect(() => {
    if (query_params.shop !== undefined) {
      fetchPlatforms();
    }
  }, [fetchPlatforms, query_params.shop]);

  return query_params.shop === undefined ? (
    <LoadingCircular />
  ) : (
    <KeepoalaThemeGetter shop={query_params.shop ?? "none"}>
      <Container
        maxWidth="sm"
        className="cardbox"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
          backgroundColor: "white",
        }}>
        <ShopLogo
          src={
            "https://storage.googleapis.com/" +
            process.env.REACT_APP_PROJECT_ID +
            "-public-data/shoplogos/" +
            query_params.shop +
            ".png"
          }
        />
        {platforms.length > 0 ? (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}>
            <InterStyled interStyle="Label 1">{`${t("Where did you buy your product")}?`}</InterStyled>
            <FormControl style={{ marginTop: 20, minWidth: 200 }}>
              <Select
                fullWidth
                value={selected_platform}
                onChange={(e) => setSelectedPlatform(e.target.value)}
                defaultValue={platforms[0]}>
                {platforms.map((platform) => (
                  <MenuItem value={platform}>{formatLabel(platform)}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              disabled={selected_platform === undefined}
              variant="contained"
              color="primary"
              style={{ marginTop: 50 }}
              onClick={() => {
                platformConfirmedHandler();
              }}>
              {t("Continue")}
            </Button>
          </div>
        ) : (
          <InterStyled interStyle="H3">{NO_PLATFORMS_FOUND}</InterStyled>
        )}
      </Container>
    </KeepoalaThemeGetter>
  );
}
